<template>
  <AboutUsCsMain/>
</template>


<script>
import AboutUsCsMain from '../components/about-us(cs)';

export default {
  name:'AboutUs',
  components:{
    AboutUsCsMain,
  }
}
</script>