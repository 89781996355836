<template>
  <div class="main-page-wrapper p0 font-gordita overflow-hidden">
    <HeaderFour :currentPage="'home'"/>
    <HeroBanner/>
    <FancyFeature4/>
    <FancyFeature3/>
    <FancyFeature/>
    <div class="overflow-hidden">
      <FooterSix/>
    </div>
  </div>
</template>

<script>
import HeaderFour from '../common/Headers/HeaderFour.vue';
import HeroBanner from './HeroBanner.vue';
import FancyFeature4 from './FancyFeature-4.vue';
import FancyFeature3 from './FancyFeature-3.vue';
import FancyFeature from './FancyFeature.vue';
import FooterSix from '../common/Footers/FooterSix.vue';

export default {
    name: "WebsiteBuilderMain",
    components: { HeaderFour, HeroBanner, FancyFeature4, FancyFeature3, FancyFeature, FooterSix }
}
</script>
