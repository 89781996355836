<template>
    <div class="fancy-feature-nineteen pt-180 md-pt-100" id="effect">
      <div class="container">
        <div class="row">
          <div
            class="col-xl-9 col-lg-11 m-auto"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <div class="title-style-eight text-center mb-80 md-mb-40">
              <h2>Who is it for!</h2>
              <!-- <p>
              </p> -->
            </div>
          </div>
        </div>
  
        <div class="row">
          <div
            v-for="item in featureData"
            :key="item.id"
            class="col-lg-6"
            data-aos="fade-up"
            data-aos-duration="1200"
            :data-aos-delay="item.delay"
          >
            <div
              class="block-style-nineteen about-content"
              :style="{ borderLeftColor: item.color }"
            >
              <div class="d-flex">
                <img :src="item.icon" alt="" class="icon" />
                <div class="text">
                  <h4 class="about-text">{{ item.title }}</h4>
                  <!-- <p>{{ item.desc }}</p> -->
                </div>
              </div>
            </div>
            <!-- /.block-style-nineteen -->
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "FancyTextBlock",
    data() {
      return {
        featureData: [
          {
            id: 1,
            color: "#FFCE22",
            icon: require(`@/assets/images/illustration/sell-international.png`),
            title: "Have been selling in Domestic but don't know how to sell international",
            desc: "",
          },
          {
            id: 2,
            color: "#8F6BF6",
            icon: require(`@/assets/images/illustration/high-marketing-spend.png`),
            title: "Even after high marketing spend low sales",
            desc: "",
            delay: "150",
          },
          {
            id: 3,
            color: "#FF5C5C",
            icon: require(`@/assets/images/illustration/sell-more.png`),
            title: "Selling internationally looking to sell on more marketplace",
            desc: "",
          },
          {
            id: 4,
            color: "#63EAA9",
            icon: require(`@/assets/images/illustration/where-to-start.png`),
            title: "Want to sell internationally but don't know where to start?",
            desc: "",
            delay: "150",
          },
        ],
      };
    },
  };
  </script>
  
  <style scoped>
    .about-content {
      min-height: 148px;
    }
    .block-style-nineteen .text h4{
      padding-bottom: 0px;
      font-size: 22px;
    }
    .icon{
      max-width: 60px;
      height: 100%;
    }
  </style>