<template>
  <div class="main-page-wrapper font-gordita overflow-hidden">
    <HeaderFour :currentPage="'about'"/>
    <FancyHero/>
    <FancyTextBlock/>
    <div class="overflow-hidden">
      <FooterSix/>
    </div>
  </div>
</template>

<script> 
import HeaderFour from '../common/Headers/HeaderFour.vue';
import FancyHero from './fancy-hero.vue';
import FancyTextBlock from './fancy-text-block.vue';
import FooterSix from '../common/Footers/FooterSix.vue';

export default {
  name:'AboutUs(cs)',
  components:{
    HeaderFour,
    FancyHero,
    FancyTextBlock,
    FooterSix
  }
}
</script>