<template>
    <div class="notifications font-gordita">
        <div class="notification enter-done">
            <div class="notification-inner">
                <div class="icon " :class="responseStatus == 'success' ? 'success' : 'error' ">
                    <svg v-if="responseStatus == 'success' ? 'd-none' : '' " stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z"></path>
                    </svg>
                    <svg v-if="responseStatus == 'error' ? 'd-none' : '' " stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"></path>
                    </svg>
                </div>
                <div>
                    <h2>{{ responseMessage }}</h2>
                    <!-- <p>{{ responseStatus }}</p> -->
                    <input type="hidden" class="repStatus" />
                </div>
                <button class="close" @click="closeNotification(responseStatus)">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    export default defineComponent({
        name: 'notificationCard',
        data() {
            return {
                closeBtnNotification: 1,
                apiStatus: ''
            }
        },
        props: {
            responseStatus: {
                type: String
            },
            responseMessage: {
                type: String
            }
        },
        methods: {
            closeNotification() {
                this.$emit('closeNotification');
            },
        },
    });
</script>

<style scoped>
    body {
        --bg: #000;
        --opacity: 1;
        --scale: 1;
        --y: 0%;
        --duration: 400ms;
    }
    .notifications {
        --width: 22rem;
        --height: 4.5rem;
        --gap: 1rem;
        position: fixed;
        top: 90px;
        pointer-events: none;
        right: 50px;
        margin-top: 10px;
        z-index: 999;
    }
    .notifications .notification {
        display: flex;
        transform: translateY(var(--y)) scale(var(--scale));
        transform-origin: center;
        transition: all var(--duration) ease-in-out;
        pointer-events: auto;
    }
    .notifications .notification-inner {
        background-color: #FFF;
        -webkit-backdrop-filter: blur(0.5rem);
        backdrop-filter: blur(0.5rem);
        padding: 0 1rem;
        border-radius: 0.5rem;
        width: var(--width);
        height: var(--height);
        margin-bottom: var(--gap);
        opacity: var(--opacity);
        transition: all var(--duration) ease-in-out;
        display: flex;
        align-items: center;
        box-shadow: 1px 1px 5px -3px #191515;
    }
    .notifications:hover .notification {
        transform: translateY(0) scale(1);
    }
    .notifications:hover .notification .notification-inner {
        opacity: 1;
    }
    .notifications .notification-inner .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 16px;
        font-size: 16px;
        padding: 12px;
        color: white;
    }
    .notifications .notification-inner .icon.info {
        background-color: #60a5fa;
    }
    .notifications .notification-inner .icon.error {
        background-color: #f87171;
    }
    .notifications .notification-inner .icon.success {
        background-color: #10b981;
    }
    .notifications .notification-inner .icon.warning {
        background-color: #f59e0b;
    }
    .notifications .notification-inner h2 {
        font-weight: 400;
        font-size: 0.8rem;
        color: #000;
        line-height: 16px;
    }
    .notifications .notification-inner p {
        margin-top: 0.3rem;
        font-size: 0.8rem;
        margin-bottom: 0px;
        line-height: 14px;
    }
    .notifications .notification-inner .close {
        background: none;
        border: none;
        position: absolute;
        right: 0;
        top: 0;
        font-size: 0.8rem;
        padding: 0.5rem;
        cursor: pointer;
        display: flex;
    }
</style>