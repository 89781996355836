<template>
  <div class="main-page-wrapper p0 overflow-hidden">
    <HeaderFour :currentPage="'signup'"/>
    <SignUpArea/>    
    <FancyFeature3/>
    <FancyTextBlock/>
    <div class="overflow-hidden">
      <FooterSix/>
    </div>
  </div>
</template>

<script>
import HeaderFour from '../common/Headers/HeaderFour.vue';
import SignUpArea from './sign-up-area.vue';
import FancyFeature3 from '../website-builder/FancyFeature-3.vue';
import FancyTextBlock from './fancy-text-block.vue';
import FooterSix from '../common/Footers/FooterSix.vue';

export default {
  components: { HeaderFour, SignUpArea, FancyFeature3, FancyTextBlock, FooterSix },
  name:'SignUpMain'
}
</script>