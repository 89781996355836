<template>
  <div :class="`theme-main-menu sticky-menu theme-menu-two ${isSticky ? 'fixed' : ''} ` + currentPage">
    <div class="d-flex align-items-center justify-content-center">
      <div class="logo">
        <router-link to="/">
          <img src="../../../assets/images/illustration/d2g.png" alt="D2G" class="logo">
        </router-link>
      </div>

      <nav id="mega-menu-holder" class="navbar navbar-expand-lg">
        <div class="nav-container">
          <button class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar"
            :class="menuActive ? 'navbar-toggler open' : 'navbar-toggler'" @click="menuActive = !menuActive">
            <span></span>
          </button>
          <div class="navbar-collapse collapse" id="collapsibleNavbar">
            <div class="d-lg-flex justify-content-between align-items-center">
              <!-- nav menu start -->
              <ul class="navbar-nav">
                <li class="nav-item">
                  <router-link class="nav-link" to="/">Home</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" to="/about-us">About Us</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" to="/signup">Sign Up</router-link>
                </li>
              </ul>
              <!-- nav menu end -->

              <ul class="right-widget">
                <li class="d-sm-flex">

                  <ul class="user-login-button d-flex align-items-center justify-content-center">
                    <li>
                      <router-link to="/signup" class="signUp-action">Go Global</router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HeaderFour',
  props: ['currentPage'],
  data() {
    return {
      isSticky: false,
      menuActive: false,
      pages_menu_data: [
        {
          url: "/about-us-cs",
          title: "About Us",
        }
      ],
    }
  },
  methods: {
    handleSticky() {
      if (window.scrollY > 80) {
        this.isSticky = true;
      }
      else {
        this.isSticky = false;
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleSticky)
  },
}
</script>

<style scoped>
.home{
  background: transparent;
}
.navbar .navbar-nav{
  font-family: gordita!important;
}
.logo{
  width: 87px;
}
.theme-menu-two .navbar-nav .nav-item:hover > .nav-link{
  color: #74c774;
}
.theme-main-menu.sticky-menu.home.fixed{
  background: #242424;
}
.navbar-collapse{
  transition: all ease-in-out;
}
.theme-menu-two .navbar .navbar-toggler{
  background: #2a2a2a;
}
.home .navbar-nav .nav-item .nav-link{
  color: #fff;
}
.theme-menu-two .user-login-button li .signUp-action{
  border: 2px solid #74c774;
  background: #74c774;
  color: #FFFFFF;
}
.theme-menu-two .user-login-button li .signUp-action:hover{
  background: transparent;
  color: #74c774;
}
@media (max-width: 991px){
  .home.theme-menu-two .navbar-collapse {
    background: #242424;
  }
  .home .navbar .navbar-collapse .navbar-nav .nav-item:last-child .nav-link{
    border-bottom: 1px solid hsla(0,0%,100%,.1);
  }
  .home .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
    border-top: 1px solid hsla(0,0%,100%,.1);
  }
  .theme-menu-two .user-login-button li .signUp-action{
    margin-left: 0px;
  }
}
</style>