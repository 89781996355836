<template>
  <div class="fancy-feature-seventeen lg-container mt-150 md-mt-90" id="feature">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 m-auto">
          <div class="title-style-thirteen text-center mb-130 md-mb-90">
            <!-- <div class="upper-title">Features</div> -->
            <h2> Why 
              <span>D2G<img src="../../assets/images/shape/line-shape-14.svg" alt=""></span>
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="bottom-border pb-50">
        <div class="row">
    
          <div v-for="item in featureData" :key="item.id" class="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1200" :data-aos-delay="item.delay">
            <div class="block-meta">
              <div class="icon d-flex align-items-end">
                <img :src="item.icon" alt="" :class="item.class">
              </div>
              <h4>{{item.title}}</h4>
              <!-- <p>{{item.subtitle}}</p> -->
            </div> <!-- /.block-meta -->
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FancyFeature',
  data () {
    return {
      featureData : [
        {id:1,icon:require(`@/assets/images/illustration/low-risk2.png`),title:'Low Risk High Reward',class:'feature-icon'},
        {id:2,icon:require(`@/assets/images/illustration/documentation.png`),title:'Document To Delivery We Setup All',delay:'100',class:'feature-icon2'},
        {id:3,icon:require(`@/assets/images/illustration/experts.png`),title:'Any Market Place We Have Experts',delay:'200',class:'feature-icon3'},
        {id:4,icon:require(`@/assets/images/illustration/high-margin.png`),title:'Higher Margin Opportunity',class:'feature-icon4'},
        {id:5,icon:require(`@/assets/images/illustration/faster-in-india.png`),title:'Faster In India To Take Your Brand Global',delay:'100',class:'feature-icon5'},
        {id:6,icon:require(`@/assets/images/illustration/growth.png`),title:'Your Growth Is Our Responsibility',delay:'200',class:'feature-icon6'},
      ]
    }
  }
}
</script>

<style scoped>
.feature-icon{
  max-width: 38px;
}
.feature-icon2{
  max-width: 36px;
}
.feature-icon3{
  max-width: 44px;
}
.feature-icon4{
  max-width: 38px;
}
.feature-icon5{
  max-width: 48px;
}
.feature-icon6{
  max-width: 42px;
}
</style>