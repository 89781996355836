<template>
  <div class="fancy-feature-thirty mt-180 md-mt-100">
    <div class="container">
      <div class="row">
        <div class="col-xl-10 col-lg-9 m-auto">
          <div class="title-style-thirteen text-center mb-80 md-mb-60">
            <!-- <div class="upper-title">Others Features</div> -->
            <h2> In the world of fastest delivery take your business
              <span>Global in 7 days<img src="../../assets/images/shape/line-shape-14.svg" alt=""></span>
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="user-data-page clearfix d-lg-flex">
    <div class="illustration-wrapper d-flex align-items-center justify-content-between flex-column">
      <h3 class="font-rubik"></h3>
      <div class="illustration-holder">
        <img src="../../assets/images/illustration/d2g-signup.png" alt="" class="illustration">
        <!-- <img src="../../assets/images/assets/ils_08.1.svg" alt="" class="shapes shape-one">
        <img src="../../assets/images/assets/ils_08.2.svg" alt="" class="shapes shape-two"> -->
      </div>
    </div>

    <div class="form-wrapper">
      <form @submit.prevent="onSubmit" class="user-data-form mt-30">
        <h2 class="pb-50">Lets build your D2G journey together</h2>
        <!-- <p class="header-info pt-30 pb-50">Already have an account?
          <router-link to="/"></router-link>
        </p> -->
        <!-- <p :class="'header-info pb-40 '+ messageColor">{{ apiResponse }}</p> -->
        <div class="row">
          <div class="col-12">
            <div class="input-group-meta input-group-form mb-50">
              <label>Full Name</label>
              <input required type="text" placeholder="" v-model="fullName" @input="onlyText">
            </div>
          </div>
          <div class="col-12">
            <div class="input-group-meta input-group-form mb-50">
              <label>Number</label>
              <input required type="text" placeholder="" v-model="mobile" @input="onlyNumbers">
              <div class="error-message">{{ mobileError }}</div>
            </div>
          </div>
          <div class="col-12">
            <div class="input-group-meta input-group-form mb-30">
              <label>Email</label>
              <input required type="email" placeholder="" v-model="email">
            </div>
          </div>          
          <div class="col-12">
            <div class="input-group-meta input-group-radio mb-20">
              <label class="mb-10">Are you selling internationally</label>
              <div class="form-check">
                <label class="form-check-label" for="sell_intl1">
                  <input type="radio" class="form-check-input" id="sell_intl1" v-model="sellIntl" value="1" checked>Yes
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label" for="sell_intl2">
                  <input type="radio" class="form-check-input" id="sell_intl2" v-model="sellIntl" value="2">No
                </label>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="input-group-meta input-group-radio mb-10">
              <label class="mb-10">Interested for</label>
              <div class="form-check">
                <label class="form-check-label" for="interest1">
                  <input type="radio" class="form-check-input" id="interest1" v-model="interest" value="1" checked>D2G
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label" for="interest2">
                  <input type="radio" class="form-check-input" id="interest2" v-model="interest" value="2">Marketplace Integration
                </label>
              </div>
            </div>
          </div>
          <!-- <div class="col-12">
            <div class="agreement-checkbox d-flex justify-content-between align-items-center sm-mt-10">
              <div>
                <input required type="checkbox" id="agree_to_policy">
                <label for="agree_to_policy">By clicking "SIGN UP" I agree to the Terms and Conditions and Privacy Policy.</label>
              </div>
            </div>
          </div> -->
          <div class="col-12">
            <button type="submit" class="theme-btn-one mt-30 mb-50" >Sign Up</button>
          </div>
          <!-- <div class="col-12">
            <p class="text-center font-rubik copyright-text">© Copyright 2021 deski</p>
          </div> -->
        </div>
      </form>
    </div> <!-- /.form-wrapper -->
  </div>  
  <NotificationCard
    v-if="showNotificationCard"
    @closeNotification = "showNotificationCard = false"
    :responseStatus="scanStatus" 
    :responseMessage="htmlMessage"
  ></NotificationCard>
</template>

<script>
import { setSignUp } from "../../api/SignupSubmit";
import NotificationCard from '../NotificationCard.vue'
export default {
  name: 'SignUpArea',
  components: {
    NotificationCard
  },
  data() {
    return {
      hidePassword: false,
      hideConfirmPassword: false,
      showNotificationCard: false,
      scanStatus: '',
      htmlMessage: '',      
      fullName: '',
      mobile: '',
      email: '',
      sellIntl :'1',
      interest :'1',
      messageColor: '',
      mobileError: '',
    }
  },
  watch:{
    showNotificationCard() {
      setTimeout(() => {
        this.showNotificationCard = false;
      }, 5000)
    }
  },
  methods: {
    async onSubmit() {
      //this.validateForm();
      // if (this.formIsValid) {
        const responseData = await setSignUp(this.fullName, this.mobile, this.email, this.sellIntl, this.interest);     
        this.scanStatus = responseData.status;
        this.htmlMessage = responseData.html_message;
        if(responseData.status === 'success') {
          this.messageColor = 'textGreen';
          this.showNotificationCard = true;          
          
          this.fullName = '';
          this.mobile = '';
          this.email = '';
          document.getElementById("sell_intl2").checked = false;
          document.getElementById("interest2").checked = false;
          document.getElementById("sell_intl1").checked = true;
          document.getElementById("interest1").checked = true;
        } else {
          this.messageColor = 'textRed';
          this.showNotificationCard = true;
        }
      // }
    },    
    onlyText() {
     this.fullName = this.fullName.replace(/[^a-zA-Z ]/g, "");
    },
    onlyNumbers() {
     this.mobile = this.mobile.replace(/[^0-9]/g, "");
     if(this.mobile.length < 10 || this.mobile.length > 10) {
      this.mobileError = "Number should be of 10 digit";
     } else {
      this.mobileError = "";
     }
    },
    handleHidePassword() {
      this.hidePassword = !this.hidePassword
    },
    handleHideConfirmPassword() {
      this.hideConfirmPassword = !this.hideConfirmPassword
    },
    validateForm() {
      if(this.firstName === "" || this.lastName === "" || this.mobileNumber ==="" || this.emailId === "" || this.vehicleType === "" || this.password === "") {
        return true;
      }
      return false;
    },
  },
}
</script>

<style scoped>
  .user-data-form .input-group-meta label.form-check-label{
    line-height: 24px;
  }
  .user-data-page .form-wrapper h2{
    font-size: 54px;
  }
  .user-data-page .form-wrapper .header-info.textGreen{
    color: green;
    font-size: 18px;
  }
  .user-data-page .form-wrapper .header-info.textRed{
    color: Red;
    font-size: 18px;
  }
  body .theme-btn-one{
    background: #74c774;
    color: #FFFFFF;
    border-radius: 22px;
  }
  .user-data-form .input-group-meta input:focus{
    border-color: #000000;
  }
  body .theme-btn-one:hover {
    background: #030303;
  }
  .error-message{
    font-size: 12px;
    color: red;
  }
  @media (max-width: 575px){
    .user-data-form .input-group-meta {
        height: auto;
    }
  }
</style>