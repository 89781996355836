<template>  
  <div class="fancy-feature-ten pt-100 md-pt-70 font-gordita" id="feature">
    <div class="bg-wrapper">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-6" data-aos="fade-right" data-aos-duration="1200">
            <div class="title-style-six">
              <h2>What we <span>offer</span></h2>
            </div>
            <!-- /.title-style-six -->
          </div>
          <div
            class="col-lg-5 col-md-6"
            data-aos="fade-left"
            data-aos-duration="1200"
          >
            <!-- <p class="sub-text pt-30 pb-30">
              We have something to offer
            </p> -->
          </div>
        </div>

        <div class="row justify-content-center mt-35 md-mt-20">
          <div
            v-for="item in fancyFeatureData"
            :key="item.id"
            class="col-lg-6 col-md-6"
            data-aos="fade-up"
            data-aos-duration="1200"
            :data-aos-delay="item.delay"
          >
            <div class="block-style-fifteen mt-40">
              <div
                class="icon d-flex align-items-center justify-content-center"
                :style="{ background: item.bg }"
              >
                <img :src="item.icon" alt="" :class="item.class"/>
              </div>
              <h3>{{ item.title }}</h3>
              <p class="offer-subtitle">{{ item.subtitle }}</p>
              <!-- <a href="#" class="mt-30"
                ><img src="../../assets/images/icon/69.svg" alt=""
              /></a> -->
            </div>
            <!-- /.block-style-fifteen -->
          </div>
        </div>
      </div>
      <img
        src="../../assets/images/shape/137.svg"
        alt=""
        class="shapes shape-one"
      />
    </div>
    <!-- /.bg-wrapper -->
  </div>
</template>

<script>
export default {
  name: 'FancyFeatureThree',
  data () {
    return {
      fancyFeatureData: [
        {
          id: 1,
          icon: require(`@/assets/images/illustration/d2g-i.png`),
          bg: "#FFEBDB",
          title: "D2G",
          subtitle: "Online domestic business will help you to take it Global",
          delay: "",
          class: "feature-icon",
        },
        {
          id: 2,
          icon: require(`@/assets/images/illustration/integration.png`),
          bg: "#E0F8F8",
          title: "Marketplace Integration",
          subtitle: "Open an account on any Global market place integration",
          delay: "100",
          class: "feature-icon1",
        },
      ],
    };
  },
};
</script>

<style scoped>
.title-style-six h2 {
  font-family: "gordita";
}
.feature-icon {
  max-width: 44px;
}
.feature-icon1{
  max-width: 34px;
}
.offer-subtitle{
  min-height: 61px;
}
.title-style-six h2 span{
  color: #74c774;
}
</style>