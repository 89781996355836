<template>
  <footer class="theme-footer-six">
    <div class="inner-container">
      <div class="container">
        <!-- <div class="row justify-content-center align-items-center">
          <div class="col-lg-4">
            <div class="logo"><router-link to="/">
              <img src="../../../assets/images/illustration/d2g.png" alt="D2G" class="logo"></router-link>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="title">Find us on Social Media</div>
            <ul class="d-flex justify-content-center social-icon">
              <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
              <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
              <li><a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
            </ul>
          </div>
          <div class="col-lg-4">
            <div class="title">We’r always happy to help.</div>
            <div class="text-center"><a href="#" class="email"></a></div>
          </div>
        </div> -->
        <div class="logo">
          <router-link to="/">
            <img src="../../../assets/images/illustration/d2g.png" alt="D2G" class="logo">
          </router-link>
        </div>
        <p class="copyright">Copyright @2023 D2G. Powered by <a href="https://www.ithinklogistics.com/" target="_blank">iThinkLogistics</a></p>
      </div>
      <img src="../../../assets/images/shape/167.png" alt="" class="shapes shape-one">
    </div> <!-- /.inner-container -->
  </footer> <!-- /.theme-footer-six -->
</template>

<script>
export default {
  name: 'FooterSix'
}
</script>

<style scoped>
.logo img{
  width: 200px;
}
.copyright{
  width: calc(100% - 200px);
  padding-top: 24px;
  vertical-align: top;
  color: #fff;
}
.copyright a:hover{
  color: #74c774
}
.theme-footer-six .inner-container{
    padding: 40px 0 20px;
}
@media (min-width: 768px){
  .logo{
    display: inline-block;
  }
  .copyright{
    display: inline-block;
  }
  .theme-footer-six .copyright{
    text-align: right;
    padding-top: 22px; 
  }
}
@media (min-width: 1200px){
  .theme-footer-six .shape-one {
      left: 30%;
  }
}
@media (max-width: 767px){
  .logo,.copyright{
    width: 100%;
    text-align: center;
  }
  .theme-footer-six .copyright{
    padding: 28px 0 0;
  }
}
</style>