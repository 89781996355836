<template>
  <div class="hero-banner-twelve lg-container" id="home">
    <div class="container">
      <div class="row">
        <div class="col-xl-11 col-lg-12 col-md-10 m-auto text-center">
          <h1 class="hero-heading font-gordita">Make your<br/><span style="color:#FF006B;">D2C</span> Brand <span style="color:#FEDC00;">D2G</span> in <br/><span style="color:#74c774;">7 Days</span></h1>
          <p class="hero-sub-heading">We will help you to take your brand Global</p>
          <a href="#" class="get-start-btn">Go Global <i class="fa fa-angle-right ms-1"
              aria-hidden="true"></i></a>
        </div>
      </div>

      <!-- <div class="screen-holder">
        <img src="../../assets/images/assets/screen_32.png" alt="" class="img-meta">
        <img src="../../assets/images/assets/screen_33.png" alt="" class="shapes screen-one">
        <img src="../../assets/images/assets/screen_34.png" alt="" class="shapes screen-two">
        <img src="../../assets/images/shape/230.svg" alt="" class="shapes shape-one">
        <img src="../../assets/images/shape/231.svg" alt="" class="shapes shape-two">
      </div>--> <!-- /.screen-holder -->
    </div>
    <!-- <img src="../../assets/images/shape/232.svg" alt="" class="shapes shape-three">
    <img src="../../assets/images/shape/233.svg" alt="" class="shapes shape-four">
    <img src="../../assets/images/shape/234.svg" alt="" class="shapes shape-five">
    <img src="../../assets/images/shape/235.svg" alt="" class="shapes shape-six">
    <img src="../../assets/images/shape/236.svg" alt="" class="shapes shape-seven">
    <img src="../../assets/images/shape/232.svg" alt="" class="shapes shape-eight"> -->
  </div>
</template>

<script>
export default {
  name: 'HeroBanner'
}
</script>

<style>
.hero-banner-twelve .hero-sub-heading {
  font-size: 36px;
}
.hero-banner-twelve .get-start-btn {
  margin-bottom: 140px;
}
#home.hero-banner-twelve .get-start-btn:hover{
  background: #74c774;
  border-color: #74c774;
}
</style>