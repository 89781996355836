<template>
  <div class="fancy-text-block-nine mt-130 md-mt-80">
    <div class="shapes shape-one"></div>
    <div class="shapes shape-two"></div>
    <div class="shapes shape-three"></div>
    <div class="shapes shape-four"></div>
    <div class="container">
      <div class="title-style-two text-center mb-35 md-mb-10">
        <div class="row">
          <div class="col-lg-10 m-auto">
            <!-- <p>Why we exist ?</p> -->
            <h2>Why we exist ?</h2>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-10 m-auto">
          <p class="text-meta" data-aos="fade-up" data-aos-duration="1200">
            India as a country is full of diversity. The Indian market has a very large variety of products to offer its customers. We want the D2C sellers that are making in India and selling in India to offer this to the world by listing them online (globally) with D2G with minimal effort and minimal cost. 
          </p>
          <!-- <p
            class="text-meta"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="100"
          >
          </p> -->
        </div>
      </div>
    </div>
  </div>
  <div class="fancy-feature-nineteen pt-180 md-pt-100" id="effect">
    <div class="container">
      <div class="row">
        <div
          class="col-xl-9 col-lg-11 m-auto"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <div class="title-style-eight text-center mb-80 md-mb-40">
            <h2>What we do ?</h2>
            <!-- <p>
            </p> -->
          </div>
        </div>
      </div>

      <div class="row">
        <div
          v-for="item in featureData"
          :key="item.id"
          class="col-lg-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          :data-aos-delay="item.delay"
        >
          <div
            class="block-style-nineteen about-content"
            :style="{ borderLeftColor: item.color }"
          >
            <div class="d-flex">
              <img :src="item.icon" alt="" class="icon" />
              <div class="text">
                <h4 class="about-text">{{ item.title }}</h4>
                <!-- <p>{{ item.desc }}</p> -->
              </div>
            </div>
          </div>
          <!-- /.block-style-nineteen -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FancyTextBlock",
  data() {
    return {
      featureData: [
        {
          id: 1,
          color: "#FFCE22",
          icon: require(`@/assets/images/illustration/growth-strategy.png`),
          title: "Define Your Growth Strategy",
          desc: "",
        },
        {
          id: 2,
          color: "#8F6BF6",
          icon: require(`@/assets/images/illustration/setup.png`),
          title: "Setup Required Document For International Business",
          desc: "",
          delay: "150",
        },
        {
          id: 3,
          color: "#FF5C5C",
          icon: require(`@/assets/images/illustration/create-and-integrate.png`),
          title: "Create & Integrate On Amazon, eBay, Etsy & More",
          desc: "",
        },
        {
          id: 4,
          color: "#63EAA9",
          icon: require(`@/assets/images/illustration/effective-marketing-strategy.png`),
          title: "Effective Marketing Strategy",
          desc: "",
          delay: "150",
        },
        {
          id: 5,
          color: "#5BE2FF",
          icon: require(`@/assets/images/illustration/faster-cheaper.png`),
          title: "Faster & Cheaper Shipping Option",
          desc: "",
        },
        {
          id: 6,
          color: "#FF56EE",
          icon: require(`@/assets/images/illustration/review-the-performance.png`),
          title: "Review The Performance",
          desc: "",
          delay: "150",
        },
      ],
    };
  },
};
</script>

<style scoped>
  .about-content {
    min-height: 148px;
  }
  .block-style-nineteen .text h4{
    padding-bottom: 0px;
  }
  .icon{
    max-width: 60px;
  }
</style>