<template>
  <div class="fancy-hero-two">
    <div class="bg-wrapper">
      <div class="container">
        <!-- <div class="page-title">About us</div> -->
        <div class="row">
          <div class="col-xl-8 col-lg-10 col-md-10 m-auto">
            <h1 class="heading">About Us</h1>
            <p class="sub-heading">
              Our vision is to make D2G the new normal. We want to create one platform for all the (D2C) brands to take their business global in the simplest, fastest, and most optimised way possible. Sellers selling internationally, we will help you grow your business by implementing an effective strategy and integrating with more marketplaces.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- /.bg-wrapper -->
  </div>
</template>

<script>
export default {
  name: "FancyHero",
};
</script>