export const setSignUp = async (fullName, mobile, email, sellIntl, interest) => {
    const params_temp = new URLSearchParams();
    params_temp.append('full_name', fullName);
    params_temp.append('email', email);
    params_temp.append('mobile', mobile);
    params_temp.append('sell_intl', sellIntl);
    params_temp.append('interest', interest);
    const result = await fetch('https://sshopy.in/api/signup-submit.php', {
        method: 'POST',
        body: params_temp,
    });
    if (result) {
        return await result.json();
    }
    return 'error';
};
