<template>
  <div class="fancy-feature-thirty mt-180 md-mt-100">
    <div class="container">
      <div class="row">
        <div class="col-xl-10 col-lg-9 m-auto">
          <div class="title-style-thirteen text-center mb-80 md-mb-60">
            <!-- <div class="upper-title">Others Features</div> -->
            <h2> Be in rare league by
              <span>earning in $<img src="../../assets/images/shape/line-shape-14.svg" alt=""></span>
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FancyFeatureFour',
}
</script>