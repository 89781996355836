import {createRouter, createWebHistory} from 'vue-router'
import HomePageOne from '../pages/home';
import AboutUs from '../pages/about-us';
import SignUp from '../pages/sign-up';
import NotFound from '../pages/404';


const routes = [
    {
        path: '/',
        name: 'HomePageOne',
        component: HomePageOne,
        meta: {
            title: 'Home || D2G',
        },
    },
    {
        path: '/about-us',
        name: 'AboutUs',
        component: AboutUs,
        meta: {
            title: 'About Us || D2G',
        },
    },
    {
        path: '/signup',
        name: 'SignUp',
        component: SignUp,
        meta: {
            title: 'Sign Up || D2G',
        },
    },
    {
        path: '/not-found',
        name: 'NotFound',
        component: NotFound,
        meta: {
            title: 'NotFound || D2G',
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
        meta: {
            title: 'NotFound || D2G',
        },
    },
   
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
